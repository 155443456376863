import { Tabs, Table, Tag, Pagination, Input, AutoComplete } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Container from '../../../components/Container';
import TrackEditor from '../../../components/TrackEditor';
import api from '../../../controllers/ApiController';
import styles from './tracks.module.scss';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import classnames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Play } from '../../../components/Player';
import { Link } from 'react-router-dom';


const { Option } = AutoComplete;


const cx = classnames.bind(styles);

const Search = ({ data }) => {

  const navigate = useNavigate();
  const [search, setSearch] = useState([]);

  const onSearch = (term) => {
    api.post('/v1/admin/search', { term }).then(res => {
      setSearch(res.data);
    })

    if (!term) {
      navigate('/admin/tracks/')
    }

  }

  const onSelect = (e) => {
    navigate('/admin/tracks/?' + e);
  }

  return <AutoComplete
    style={{
      width: 200,
    }}
    onSelect={onSelect}
    onSearch={onSearch}
    placeholder="Search anything"
  >
    {search.map((result) => (
      <Option key={result.value} value={result.value}>
        {result.label}
      </Option>
    ))}
  </AutoComplete>
}

function App() {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [isDataLoading, setDataLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(1);
  const [stems, setStems] = useState(false);
  const [versions, setVersions] = useState(false);
  const audioRef = useRef();
  const dispatch = useDispatch();
  const userId = searchParams.get('userId');
  const trackId = searchParams.get('trackId');
  const briefId = searchParams.get('briefId');


  const player = useSelector(state => state.player);

  const fetchMusic = (p) => {
    setDataLoading(true);
    api.get(`/v1/admin/tracks?page=${p}&userId=${userId}&trackId=${trackId}&briefId=${briefId}&status=${status}`).then(res => {
      setData(res.data);
      setLoading(false);
      setDataLoading(false);
    })
  }


  useEffect(() => {
    fetchMusic(page);
  }, [page, status, userId, trackId, briefId])

  const onUpdate = (id) => {

    api.get('/v1/admin/tracks/' + id).then(res => {
      setEdit(res.data);
      setData({ ...data, rows: data.rows.map(v => v.id === id ? res.data : v) });
    })

  }

  const onPlay = (track) => {

    if (player.track.id === track.id) {
      dispatch({
        type: player.isPlay ? "PLAYER_PAUSE" : "PLAYER_PLAY"
      });
    } else {
      dispatch({ type: "PLAYER_SET_TRACK", params: { track } });
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'preview',
      key: 'preview',
      render: (preview, track) => (<Play isPlay={player.isPlay && track.id === player.track.id} onClick={() => onPlay(track)} small />)
    },
    {
      title: 'Artist',
      dataIndex: 'User',
      key: 'User',
      render: (User) => User.name
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Genres',
      dataIndex: 'genres',
      key: 'genres',
      render: (genres) => (genres.join(', '))

    },
    {
      title: 'Stems',
      dataIndex: 'TrackStem',
      key: 'TrackStem',
      render: (TrackStem) => (TrackStem?.status >= 2 ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>)
    },
    {
      title: 'Versions',
      dataIndex: 'TrackVersion',
      key: 'TrackVersion',
      render: (TrackVersion) => (TrackVersion?.status >= 2 ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>)
    },
    {
      title: 'Brief',
      dataIndex: 'Brief',
      key: 'Brief',
      render: (value) => (value ? <Link to={`/admin/tracks/?briefId=${value.id}`}>{value.genre}</Link> : '')
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {

            let color;

            switch (tag) {
              case "rejected (soft)":
                color = 'red';
                break;
              case "rejected":
                color = 'red';
                break;
              case "review":
                color = 'orange';
                break;
              case "stems":
                color = 'red';
                break;
              case "finished":
                color = 'green';
                break;
              default:
                color = 'grey';
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return record.status === 0 ? <a onClick={() => setEdit(record)}>Edit</a> : <a onClick={() => setEdit(record)}>View</a>
      },
    },
  ];

  const getTags = (v) => {
    const tags = [];

    if (v.status === -2) {
      tags.push('rejected');
    }

    if (v.status === -1) {
      tags.push('rejected (soft)');
    }

    if (v.status === 0) {
      tags.push('draft');
    }

    if (v.status === 1) {
      tags.push('review');
    }

    if (v.status === 2 && !v.TrackStem) {
      tags.push('stems');
    }

    if (v.status === 11) {
      tags.push('finished');
    }

    return tags;
  }

  const prepare = ({ rows }) => {

    if (!rows) return [];

    return rows.map(v => {
      return {
        key: v.id,
        ...v,
        tags: getTags(v)
      }
    })
  }

  const tabs = [
    { label: 'Review', key: 'review', children: '' }, // remember to pass the key prop
    { label: 'Approved', key: 'approved', children: '' },
    { label: 'Finished', key: 'finished', children: '' },
    { label: 'Rejected', key: 'rejected', children: '' },
    { label: 'Rejected (Soft)', key: 'soft-rejected', children: '' },
  ];

  const onClickTab = (e) => {

    let _status;
    let _stems = true;
    let _versions = true;

    switch (e) {
      case "approved":
        _stems = false;
        _versions = false;
        _status = 2;
        break;
      case "soft-rejected":
        _status = -1;
        break;
      case "rejected":
        _status = -2;
        break;
      case "finished":
        _status = 11;
        break;
      default:
        _status = 1;
    }

    setPage(1)
    setStatus(_status);
    setVersions(_versions);
    setStems(_stems);

  }

  return <Container title="Tracks" isLoading={isLoading} extra={<Search />}>
    {(!userId && !trackId) && <Tabs items={tabs} onChange={onClickTab} />}
    <br />
    <Table columns={columns} loading={isDataLoading} dataSource={prepare(data)} pagination={{
      position: ['none', 'none'], pageSize: 50
    }} />
    <br />
    {data.count > 20 && <Pagination defaultCurrent={page} total={data.count} pageSize={20} onChange={setPage} />}
    <TrackEditor data={edit} onClose={() => setEdit(null)} admin onUpdate={onUpdate} />
    <audio ref={audioRef} style={{ display: 'none' }} />
    <div className="h-24" />
  </Container>
}

export default App;
