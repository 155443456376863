import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import user from './user';
import player from './player';
import mySaga from '../sagas';

const sagaMiddleware = createSagaMiddleware()
const reducers = combineReducers({
  user,
  player,
});

const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
)

sagaMiddleware.run(mySaga)

export default store;

