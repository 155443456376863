import { Table, Tag, Pagination, Modal, Button } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import Container from '../../components/Container';
import TrackEditor from '../../components/TrackEditor';
import api from '../../controllers/ApiController';
import styles from './tracks.module.scss';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

function App({ admin }) {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [status, setStatus] = useState(1);
  const audioRef = useRef();

  const fetchStems = (p) => {
    api.get(`/v1/admin/versions?page=${p}`).then(res => {
      setData(res.data);
      setLoading(false);
    })
  }


  useEffect(() => {
    fetchStems(page);
  }, [page, status])


  const columns = [
    {
      title: 'Track',
      dataIndex: 'Track',
      key: 'Track',
      render: (track) => track.title
    },
    {
      title: 'Count',
      dataIndex: 'data',
      key: 'data',
      render: (_) => (<div>{_.length}</div>)
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_) => (<div></div>
        // <>
        //   {tags.map((tag) => {

        //     let color;

        //     switch (tag) {
        //       case "rejected":
        //         color = 'red';
        //         break;
        //       case "review":
        //         color = 'orange';
        //         break;
        //       case "approved":
        //         color = 'green';
        //         break;
        //       case "stems":
        //         color = 'red';
        //         break;
        //       case "versions":
        //         color = 'red';
        //         break;
        //       case "finished":
        //         color = 'green';
        //         break;
        //       case "offers":
        //         color = 'green';
        //         break;
        //       default:
        //         color = 'grey';
        //     }

        //     return (
        //       <Tag color={color} key={tag}>
        //         {tag.toUpperCase()}
        //       </Tag>
        //     );
        //   })}
        // </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return record.status === 0 ? <a onClick={() => setEdit(record)}>Edit</a> : <a onClick={() => editTrack(record.Track.id)}>View</a>
      },
    },
  ];

  const onUpdate = () => {

  }

  const editTrack = (id) => {
    api.get(`/v1/admin/tracks/${id}`).then(res => {
      setEdit(res.data);
    })
  }

  const prepare = ({ rows }) => {

    if (!rows) return [];

    return rows.map(v => {
      return {
        key: v.id,
        ...v,
      }
    })
  }

  return <Container title="Versions" isLoading={isLoading}>
    <Table columns={columns} dataSource={prepare(data)} pagination={{
      position: ['none', 'none'], pageSize: 50
    }} />
    <br />
    {data.count > 20 && <Pagination defaultCurrent={page} total={data.count} pageSize={20} onChange={setPage} />}
    <TrackEditor data={edit} onClose={() => setEdit(null)} admin onUpdate={onUpdate} />
    <audio ref={audioRef} style={{ display: 'none' }} />
  </Container >
}

export default App;
