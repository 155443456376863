import 'antd/dist/reset.css';
import './App.scss';
import './index.css';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Provider, useDispatch } from 'react-redux'

import Tracks from './pages/Tracks';
import Payments from './pages/Payments';


import AdminContracts from './pages/Admin/Contracts';
import AdminTracks from './pages/Admin/Tracks';
import Briefs from './pages/Briefs';
import Profile from './pages/Profile';
import Contracts from './pages/Contracts';
import Stems from './pages/Stems';
import Versions from './pages/Versions';
import Faq from './pages/Faq';
import Logout from './pages/Logout';
import Layout from './Layout';
import store from './reducers'
import NotFound from './pages/NotFound';

import api from './controllers/ApiController';

function App() {



  const Logout = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch({ type: "USER_LOGOUT" });

    api.get('/v1/user/logout').then(res => {

    }).finally(() => {
      navigate('/');
    })

    return null;
  }

  return <Provider store={store}><Routes>
    <Route>
      <Route path="/logout/" element={<Logout />} />
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/briefs/" />} />
        <Route path="/tracks/" element={<Tracks />} />
        <Route path="/contracts/" element={<Contracts />} />
        <Route path="/payments/" element={<Payments />} />
        <Route path="/briefs/" element={<Briefs />} />
        <Route path="/profile/" element={<Profile />} />
        <Route path="/faq/" element={<Faq />} />
      </Route>
      <Route element={<Layout admin />}>
        <Route path="/admin/tracks/" element={<AdminTracks />} />
        <Route path="/admin/contracts/" element={<AdminContracts />} />
        <Route path="/admin/briefs/" element={<Briefs admin />} />
        <Route path="/admin/stems/" element={<Stems />} />
        <Route path="/admin/versions/" element={<Versions />} />
      </Route>
      <Route path="/logout/" element={<Logout />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
  </Provider>;
}

export default App;
