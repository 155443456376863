import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

const Button = ({ children, size, onClick, href, submit, block, red }) => {


  const className = classNames("whitespace-nowrap rounded bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700", { "w-full": block, "!bg-red-600 hover:!bg-red-500": red });

  return href ? <Link to={href} className={className}>{children} </Link> : <button
    type={submit ? "submit" : "button"}
    onClick={onClick}
    className={className}
  >
    {children}
  </button >
}

export default Button;

