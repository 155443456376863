import { Space, Table, Divider, Button, Drawer, Form, Input, Tabs, Tag, Select, Radio, Timeline, Alert, Progress, Steps } from 'antd';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filters from '../../filters.json';
import { Link } from 'react-router-dom';
import api from '../../controllers/ApiController';
import styles from './editor.module.scss';
import Play from '../../components/Play';
import Helpers from '../../utils/Helpers';
import { useTranslation } from 'react-i18next';

import { CloudDownloadOutlined, CloseOutlined } from '@ant-design/icons';


const { Option } = Select;

const History = ({ id }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.get(`/v1/tracks/${id}/history`).then(res => {
      if (res.data) {
        setData(res.data);
      }
    }).catch(e => {

    })
  }, id);

  return <Timeline
    items={data.map(v => {
      return { children: v.message, color: v.status === 0 ? 'green' : 'red' }
    })}
  />
}

const ApproveReject = ({ type, data, onUpdate }) => {

  const [isFinished, setFinished] = useState(false);
  const [isReject, setReject] = useState(false);
  const [rejectForm] = Form.useForm();


  const onApprove = () => {
    setFinished(true);
    const url = type === 'track' ? '/v1/admin/tracks/approve' : `/v1/admin/tracks/${type}/approve`;
    api.post(url, { id: data.id }).then(res => {

    }).catch(e => {
      setFinished(false);
    })
  }

  const onReject = (e) => {
    const reason = rejectForm.getFieldValue('reason');
    setFinished(true);
    const url = type === 'track' ? '/v1/admin/tracks/reject' : `/v1/admin/tracks/${type}/reject`;
    api.post(url, { id: data.id, reason }).then(res => {

    }).catch(e => {
      setFinished(false);
    })
  }

  if (isFinished) {
    return null;
  }

  if (isReject) {
    return <Form
      form={rejectForm}
      onFinish={onReject}
    >
      <Form.Item label="Reason" name="reason">
        <Input.TextArea placeholder="" rows={4} />
      </Form.Item>
      <div className="flex gap-2 w-full">
        <Button type="primary" danger onClick={onReject} block>Reject</Button>
        <Button type="primary" onClick={() => setReject(false)} block className="bg-blue-500">Cancel</Button>
      </div>
    </Form>
  }

  return <div className="flex gap-2 w-full">
    <Button onClick={() => setReject(true)} type="primary" block danger>Reject {type}</Button>
    <Button onClick={onApprove} type="primary" className="bg-green-500 hover:bg-green-600" block>Approve {type}</Button>
  </div >
}


const Track2 = ({ data }) => {
  const wave = useRef();
  const wavesurfer = useRef();
  const [isLoaded, setLoaded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  const { title, preview, duration, waveform } = data;

  useEffect(() => {

    if (wave.current) {

      const waveOptions = {
        container: wave.current,
        waveColor: '#003bff',
        progressColor: '#00249b',
        forceDecode: false,
        height: 20,
        normalize: true,
        barWidth: 2,
        barRadius: 2,
        duration,
        plugins: [],
      }

      if (window.WaveSurfer.cursor) {
        waveOptions.plugins.push(window.WaveSurfer.cursor.create({
          showTime: true,
          formatTimeCallback: (timeValue) => Helpers.toMinutes(timeValue),
          opacity: 1,
          color: 'white',
          customShowTimeStyle: {
            'background-color': '#1677ff',
            'color': 'white',
            'font-size': '12px',
            'padding': '4px',
          }
        }))
      }

      wavesurfer.current = window.WaveSurfer.create(waveOptions);

      fetch(waveform).then(response => response.json()).then(res => {
        wavesurfer.current.backend.peaks = res.data;
        wavesurfer.current.drawBuffer();
      }).catch(e => {

      })

      wavesurfer.current.on('loading', () => {
        setLoading(true);
      })

      wavesurfer.current.on('ready', () => {
        setLoaded(true);
        setLoading(false);
        wavesurfer.current.play();
      })

      wavesurfer.current.on('play', () => {
        setPlaying(true);
      })

      wavesurfer.current.on('pause', () => {
        setPlaying(false);
      })

    }

  }, []);

  const onPlayPause = () => {



    if (isLoaded) {
      if (isPlaying) {
        wavesurfer.current.pause();
      } else {
        wavesurfer.current.play();
      }
    } else if (!isLoaded) {



      wavesurfer.current.load(preview);
    }


  }

  return <div className="flex bg-gray-100 items-center">
    <div className="mr-2">
      <Play onPlay={onPlayPause} isPlay={isPlaying} />
      {/* <button onClick={onPlayPause}>{isPlaying ? '||' : '>'}</button> */}
    </div>
    <div className="flex w-full">
      <div>{title}</div>
      <div ref={wave} className="w-full relative" />
    </div>
  </div>

}

const Track = ({ data, type }) => {

  const [isActive, setActive] = useState(false);
  const [isApproved, setApproved] = useState(false);


  const Stem = ({ id, stem, duration }) => {

    const wave = useRef();
    const wavesurfer = useRef();
    const [isLoaded, setLoaded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {

      if (wave.current) {
        wavesurfer.current = window.WaveSurfer.create({
          container: wave.current,
          waveColor: '#003bff',
          progressColor: '#00249b',
          forceDecode: false,
          height: 20,
          normalize: true,
          barWidth: 2,
          barRadius: 2,
          duration,
        });

        fetch(`https://soundacquire.s3.amazonaws.com/${type}/${id}/wave/${stem}.json`).then(response => response.json()).then(res => {
          wavesurfer.current.backend.peaks = res.data;
          wavesurfer.current.drawBuffer();
        }).catch(e => {

        })

        wavesurfer.current.on('loading', () => {
          setLoading(true);
        })

        wavesurfer.current.on('ready', () => {
          setLoaded(true);
          setLoading(false);
          wavesurfer.current.play();
        })

        wavesurfer.current.on('play', () => {
          setPlaying(true);
        })

        wavesurfer.current.on('pause', () => {
          setPlaying(false);
        })

      }

    }, []);


    const onPlayPause = () => {

      if (isLoaded) {
        if (isPlaying) {
          wavesurfer.current.pause();
        } else {
          wavesurfer.current.play();
        }
      } else if (!isLoaded) {
        wavesurfer.current.load(`https://soundacquire.s3.amazonaws.com/${type}/${id}/mp3/${stem}.mp3`);
      }


    }

    return <div className={styles.stem}>
      <div><button onClick={onPlayPause}>{isPlaying ? '||' : '>'}</button></div>
      <div>
        <div>{stem}</div>
        <div ref={wave} />
      </div>
    </div>
  }
  return <div className={styles.stems}>
    {data.data.map(stem => (<Stem key={`stem_${stem}`} id={data.id} stem={stem} duration={0} />))}
  </div>
}



const App = ({ data, onClose, admin, onUpdate }) => {

  const dispatch = useDispatch();
  const [uploading, setUploading] = useState({ versions: false, stems: false });
  const [submitted, setSumbitted] = useState({ versions: false, stems: false });
  const [progress, setProgress] = useState({ versions: 0, stems: 0 });
  const [error, setError] = useState();
  const [isOfferAccepted, setOfferAccepted] = useState(false);
  const [success, setSuccess] = useState();
  const [isReject, setReject] = useState(false);
  const [checkedVersions, setCheckedVersions] = useState(true);
  const [checkedStems, setCheckedStems] = useState(true);
  const [uploadingTrack, setUploadingTrack] = useState(false);
  const [track, setTrack] = useState(data);
  const { t } = useTranslation('tracks');
  const [tab, setTab] = useState('tab-details');

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
      setUploadingTrack(false);
      setTab('tab-details');
    }
  }, [data]);


  const makeOffer = (e) => {
    e.preventDefault();

    const versions = checkedVersions;
    const stems = checkedStems;
    const price = e.target.price.value;

    if (!Number.isInteger(parseInt(price)) || parseInt(price) < 1 || parseInt(price) > 10000) {
      return alert('Wrong Price');
    }

    api.post('/v1/admin/offers/make', { trackId: data.id, price, versions, stems }).then(res => {
      onUpdate(data.id);
    })

  }


  const acceptOffer = (id) => {
    api.post('/v1/offers/accept', { id }).then(res => {
      setError(null);
      onUpdate(data.id);
      setOfferAccepted(true);
    }).catch(e => {
      setError(e?.response?.data?.error ?? 'Unexpeceted error')
    })
  }

  const deleteOffer = (id) => {
    api.post('/v1/admin/offers/delete', { id }).then(res => {
      onUpdate(data.id);
    })
  }

  const handleUpload = async (e, type) => {

    const formData = new FormData();
    const token = type === 'stems' ? data.TrackStem.token : data.TrackVersion.token;
    formData.append("zip", e.target.files[0]);
    formData.append("token", token);
    setUploading({ ...uploading, [type]: true });
    api.post(process.env.REACT_APP_UPLOAD_URL + `/v1/upload/${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress({ ...progress, [type]: percentCompleted });
      }
    }).then(res => {
      setSumbitted({ ...submitted, [type]: true });
    }).catch(e => {
      setError(e?.response?.data?.message || "Error");
    }).finally(() => {
      setUploading(false);
    })
  }

  const handleUploadTrack = async (e) => {

    setUploadingTrack(true);

    var formData = new FormData();
    var file = document.querySelector('#preview');
    formData.append("audio", file.files[0]);
    formData.append("trackId", data.id);
    api.post('/v1/tracks/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        // var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        // setProgress(percentCompleted);
      }
    }).then(res => {
    }).finally(() => {
      e.target.value = ''
      onUpdate(data.id);
    })
  }

  const [form] = Form.useForm();
  const [rejectForm] = Form.useForm();

  const onFinish = (values) => {
    api.post('/v1/tracks/update', { id: data.id, ...values }).then(res => {
      onUpdate(data.id);
    });
  };




  const Offers = () => {



    const getTags = (v) => {
      if (v.status === 1) {
        return ['accepted'];
      }

      if (v.status === 0 && admin) {
        return ['delete'];
      }

      if (v.status === 0 && !admin) {
        return ['accept'];
      }
    }

    if (data?.Offers?.length > 0) {

      return <Space direction='vertical' style={{ width: '100%' }}>
        {isOfferAccepted && <div>You have accepted your offer. <Link to="/contracts/">Go to contracts.</Link></div>}
        {error && <Alert message={error} type="error" />}
        <Table pagination={{
          position: ['none', 'none'],
        }} columns={[
          {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'amount',
            render: (text) => <span>{text}</span>,
          },
          {
            title: 'Amount',
            dataIndex: 'price',
            key: 'amount',
            render: (text) => <span>${text}</span>,
          },
          {
            title: '',
            key: 'tags',
            dataIndex: 'tags',
            render: (tags, record) => (
              <span>

                {tags.map((tag) => {

                  if (tag === 'accept') {
                    return <Button type="primary" size="small" onClick={() => acceptOffer(record.id)} className="bg-blue-500">Accept</Button>
                  }
                  let color;
                  if (tag === 'accepted') {
                    color = 'green';
                  }

                  if (admin && tag === 'delete') {
                    return <Button type="primary" size="small" onClick={() => deleteOffer(record.id)} className="bg-red-500">Delete</Button>
                  }

                  return (
                    <Tag color={color} key={tag}>
                      {tag.toUpperCase()}
                    </Tag>
                  );
                })}
              </span>
            ),
          },]} dataSource={data.Offers.map(v => {
            return { key: v.id, ...v, tags: getTags(v) }
          })} />
      </Space>

    } else {
      if (admin) {

        return <form onSubmit={makeOffer}>
          <Table columns={[
            { key: "price", title: "Price", render: () => (<input name="price" className="border" />) },
            { key: "versions", title: "Versions", render: () => (<input name="versions" type="checkbox" checked={checkedVersions} onChange={() => setCheckedVersions(!checkedVersions)} />) },
            { key: "stems", title: "Stems", render: () => (<input name="stems" type="checkbox" checked={checkedStems} onChange={() => setCheckedStems(!checkedStems)} />) }]} dataSource={[{ name: "price" }]} pagination={{
              position: ['none', 'none']
            }} />
          <br />
          <Button type="primary" block htmlType="submit" danger>Make offer</Button>
        </form>

        // return <Search placeholder="Price" enterButton="Make offer" size="large" loading={false} onSearch={makeOffer} />
      }
      return <div>You will receive your offer once your track is approved</div>
    }

  }

  const StemsVersions = ({ type }) => {

    const _data = type === 'stems' ? data?.TrackStem : data?.TrackVersion;
    const status = type === 'stems' ? _data?.status : _data?.status;

    if (!admin) {
      if (uploading[type]) {
        return <Progress percent={progress[type]} />;
      }
      if (status === 0 && !submitted[type]) {
        return <div className="flex flex-col gap-4">
          <Alert type="warning"
            showIcon
            message={`The archive should contain only WAV 48000 files without folders.`} />

          <a href="https://docs.google.com/document/d/18ilX5eNSUz7YYvG3Dee66BO3Gj0ErPY-wSXgjy-_1yE/edit" target="_blank" className="underline">Как делать версии и стемы?</a>

          <div className={styles.upload}>
            <label htmlFor={`upload_${type}_${data.id}`}>
              <input id={`upload_${type}_${data.id}`} name="file" type="file" accept=".zip" onChange={(e) => handleUpload(e, type)} title="Upload" disabled={uploading[type] || submitted[type]} />
              {uploading[type] ? 'Uploading...' : `Upload ${type}`}
            </label>
          </div></div>
      }
    }

    if ([-1, 0, 1].includes(data?.status)) {
      return <Alert type="info"
        showIcon
        message={`You are able to upload ${type} when your track will be approved`} />
    }
    if ([2, 3].includes(status)) {
      return <Space direction='vertical' style={{ width: '100%' }}>
        {status === 2 && <Alert message={`The ${type} have been sent for review.`} type="info" />}
        {status === 3 && <Alert message="Stems approved" type="success" />}
        {admin && <Track data={_data} type={type} />}
        {admin && status === 2 && <ApproveReject type={type} data={_data} />}
        {admin && <Button block onClick={() => onDownload(type, _data?.id)}>Download {type}</Button>}
      </Space>
    }

    if (status === 1 || submitted[type]) {
      return <Alert message={`The ${type} have been sent for review.`} type="info" />
    }

    return null;

  }






  const onDownload = (type, id) => {
    api.get(`/v1/admin/download/${type}/${id}`).then(res => {
      if (res.data.file) {
        document.location.href = res.data.file;
      }
    })
  }

  const Wavs = () => {
    const [rejectWav, setRejectWav] = useState();
    const [rejectForm] = Form.useForm();
    const onClickRejectWav = ({ reason }) => {
      if (rejectWav) {
        api.post('/v1/admin/wav/reject', { id: rejectWav.id, reason }).then(res => {
          onUpdate(data.id);
        })
      }
    }

    const isUpload = () => {
      let upload = true;
      if (Array.isArray(data?.TrackWavs)) {
        data?.TrackWavs.forEach((v) => {
          if (v.status >= 0) {
            upload = false;
          }
        })
      }
      return upload;
    }

    return <>
      {(isUpload() && !admin && !uploadingTrack) &&
        <label for="preview" className={styles.uploadPreview}>{t('UPDATE_WAV')}
          <input id="preview" name="file" type="file" accept=".wav" onChange={handleUploadTrack} />
        </label>}

      {uploadingTrack && <div className="mb-4 bg-gray-100 p-4 rounded-lg flex justify-center py-12 mb-12">
        Uploading...
      </div>}

      <div>
        {data?.TrackWavs && data?.TrackWavs.map(v => {
          return <div className="">
            <h2>{v.originalFileName}</h2>
            <div className="mb-4 bg-gray-100 p-4 rounded-lg">
              <Track2 data={v} />
              <div className="flex justify-between mt-4">
                <div>{v.createdAt}</div>
                {v.status === -1 && <Tag color="orange" size={8}>Rejected</Tag>}
                {admin && <div>
                  <Space size={8}>
                    <Button icon={<CloudDownloadOutlined />} size={2} onClick={() => onDownload('wav', v.id)} />
                    {(admin && v.status === 0) && <Button type="primary" danger icon={<CloseOutlined />} onClick={() => setRejectWav(v)} />}
                  </Space>
                </div>}
              </div>
            </div>
            {v.message && <Alert message={v.message} type="warning" />}

            {rejectWav?.id === v.id && <Form
              form={rejectForm}
              layout="vertical"
              onFinish={onClickRejectWav}
            >
              <br />
              <Form.Item label="Reason" name="reason">
                <Input.TextArea placeholder="" rows={4} />
              </Form.Item>
              <div className="flex gap-2 w-full">
                <Button type="primary" danger block htmlType="submit">Reject</Button>
                <Button type="primary" onClick={() => setReject(null)} block className="bg-blue-500">Cancel</Button>
              </div>
            </Form>}

            <Divider />
          </div>
        })}
      </div>






    </>
  }

  const Download = () => {
    return null;
  }

  let currentStep;
  let status;

  if (data?.status === 0) {
    currentStep = 0;
  }

  if (data?.status === 1) {
    currentStep = 2;
  }

  if (data?.status === 2) {
    currentStep = 3;
  }

  if (data?.TrackVersion?.status === 0 || data?.TrackStem?.status === 0) {
    currentStep = 4;
  }

  if (data?.status === 11) {
    currentStep = 7;
  }

  if (data?.status === -1) {
    currentStep = 2;
    status = 'error';
  }

  const tabs = [
    {
      label: t('DETAILS'), key: 'tab-details', children: <div className={styles.form}><Form
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        disabled={(!admin && ![-1, 0].includes(data?.status))}
      >
        <Form.Item label="Title" name="title" rules={[{
          required: true,
          message: 'Only latin letters are allowed',
          pattern: new RegExp(/^[a-zA-Z0-9!?\- ]*$/)
        }]}>
          <Input placeholder="" size="large" />
        </Form.Item>

        {['themes', 'genres', 'moods', 'instruments'].map(v => {
          return <Form.Item label={v} name={v} rules={[{
            required: true,
          }]} >
            <Select mode="tags" size="large">
              {filters[v].map(v2 => <Option key={v2.alias}>{v2.name}</Option>)}
            </Select>
          </Form.Item>
        })}
        <Form.Item label="BPM" name="bpm" rules={[{
          required: true,
          message: 'Only latin numbers are allowed',
          pattern: new RegExp(/^[0-9]*$/)
        }]} >
          <Input placeholder="" size="large" />
        </Form.Item>
        <Form.Item label="Lyrics" name="lyrics">
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        {<div className="mb-4">
          <Button htmlType="submit" block type="primary" className="bg-blue-500">Save</Button>
        </div>}

        {(admin && [0, -1, 1].includes(data?.status)) && <ApproveReject type="track" data={data} />}




      </Form>
      </div >
    },
    {
      label: t('PROGRESS'), key: 'tab-progress', children: <Steps
        direction="vertical"
        current={currentStep}
        status={status}
        items={[
          {
            title: t('PROGRESS_UPLOAD_WAV'),
            description: t('PROGRESS_UPLOAD_WAV_DESCRIPTION')
          },
          {
            title: t('PROGRESS_TRACK_INFO'),
            description: t('PROGRESS_TRACK_INFO_DESCRIPTION')
          },
          {
            title: t('PROGRESS_REVIEW'),
            description: data?.status === -1 ? t('PROGRESS_REVIEW_DESCRIPTION_1') : t('PROGRESS_REVIEW_DESCRIPTION_2')
          },
          {
            title: t('PROGRESS_APPROVAL'),
            description: t('PROGRESS_APPROVAL_DESCRIPTION'),
          },
          {
            title: t('PROGRESS_UPLOAD_VERSIONS'),
            description: t('PROGRESS_UPLOAD_VERSIONS_DESCRIPTION'),
          },
          {
            title: t('PROGRESS_REVIEW_VERSIONS'),
            description: t('PROGRESS_REVIEW_VERSIONS_DESCRIPTION'),
          },
          {
            title: t('PROGRESS_CONTRACT'),
            description: t('PROGRESS_CONTRACT_DESCRIPTION'),
          },
        ]}
      />
    },
    { label: 'WAVs', key: 'tab-wav', children: <Wavs /> },
    { label: t('STEMS'), key: 'tab-stems', children: <StemsVersions type="stems" /> },
    { label: t('VERSIONS'), key: 'tab-versions', children: <StemsVersions type="versions" /> },
    { label: t('OFFERS'), key: 'tab-offers', children: <Offers /> },
    { label: t('HISTORY'), key: 'tab-history', children: <History id={data?.id} /> },
  ];

  return <Drawer title={data?.title} placement="right" onClose={onClose} open={!!data} width={admin ? '50%' : 605}>
    <Tabs items={tabs} onChange={setTab} activeKey={tab} />
  </Drawer >

};

export default App;