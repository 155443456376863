import { Input, Typography } from 'antd';
import React, { useState, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './container.module.scss';
const { Title } = Typography;

const Container = ({ title, children, isLoading, extra }) => {

  if (isLoading) {
    return <div className={styles.loading}>
      <LoadingOutlined
        style={{
          fontSize: 32,
          color: '#003bff',
        }}
        spin
      />
    </div>
  }

  return <>
    <div className={styles.header}>
      <Title level={3}>{title}</Title>
      {extra}
    </div>
    <div className={styles.container}>
      {children}
    </div>
  </>

};

export default Container;