const initialState = {
  id: null,
  email: null,
  isAuthorized: null,
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_SET':
      return { ...state, ...action.payload };
    case 'USER_LOGOUT':
      return { ...initialState, isAuthorized: false };
    case 'USER_RESET':
      return { ...initialState };
    default:
      return state;
  }
};

export default User;
