import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import api from '../controllers/ApiController';

function* fetchMusic() {
  try {
    const { data } = yield api.get('/v1/tracks');
    yield put({ type: "MUSIC_SET", payload: data });
  } catch (e) {
    //yield put({ type: "WHITELISTING_FETCH_FAILED", message: e.message });
  }
}

function* userLogout() {
  try {
    const { data } = yield api.get('/v1/user/logout');
    yield put({ type: "USER_LOGOUT" });
  } catch (e) {
    //yield put({ type: "WHITELISTING_FETCH_FAILED", message: e.message });
  }
}



function* mySaga() {
  //yield takeEvery("USER_LOGOUT", userLogout);
}

export default mySaga;