import { Tabs, Table, Tag, Drawer, Alert, Form, Input, Select } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Container from '../../components/Container';
import api from '../../controllers/ApiController';
import styles from './profile.module.scss';
import countries from '../../countries.json';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';



const { Option } = Select;
const Persona = require('persona');

function App() {

  const [isLoading, setLoading] = useState(true);
  const [isDisabled, setDisabled] = useState(true);
  const [editContact, setEditContact] = useState();
  const [contacts, setContacts] = useState();
  const [isVerify, setVerify] = useState();
  const [editPro, setEditPro] = useState();
  const [pro, setPro] = useState();
  const { t } = useTranslation('profile');


  const fetchContacts = () => {
    api.get('/v1/profile/contacts').then(res => {
      setContacts(res.data);
      setLoading(false)
    }).catch(e => {

    });
  }

  const fetchPro = () => {
    api.get('/v1/profile/pro').then(res => {
      setPro(res.data);
    }).catch(e => {

    });
  }

  useEffect(() => {
    fetchContacts();
    fetchPro();
  }, []);

  useEffect(() => {
    if (editPro) {
      formPro.setFieldsValue(editPro);
    }
  }, [editPro]);

  useEffect(() => {
    if (editContact) {
      form.setFieldsValue(editContact);
    }
  }, [editContact]);


  const updateVerification = (id, e) => {

    api.post('/v1/profile/contacts/verify', { id, ...e }).then(res => {
      fetchContacts();
    }).catch(e => {

    });
  }

  const onCancel = () => {
    setVerify(false);
  }


  const onVerify = (record) => {

    if (isVerify) {
      return false;
    }

    setVerify(true);

    const prefill = {
      nameFirst: record.firstName,
      nameLast: record.lastName,
      countryCode: record.country,
      //emailAddress: "janedoe@persona.com"
    }

    const client = new Persona.Client({
      templateId: "itmpl_JNL69HtvJBLLnFvT1oFHD52f",
      environment: "production",
      prefill,
      onReady: () => client.open(),
      onComplete: (e) => updateVerification(record.id, e),
      onCancel: onCancel,
      onError: (error) => console.log(error),
    });
  }

  const proColumns = [
    {
      title: 'Organzation',
      dataIndex: 'pro',
      key: 'pro',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        return <div>{record.firstName} {record.lastName}</div>
      },
    },
    {
      title: 'Identificator',
      dataIndex: 'identificator',
      key: 'identificator',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return <Button onClick={() => setEditPro(record)}>Edit</Button>
      },
    }]

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return <div>{record.firstName} {record.lastName}</div>
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => {
        return <div>{record.address1} {record.address2}, {record.city}, {record.state} {record.zip}, {record.country}</div>
      },
    },
    {
      title: 'Status',
      dataIndex: 'verified',
      key: 'verified',
      render: (value, record) => {
        return <Tag color={value ? 'green' : 'red'}>
          {value ? 'Verified' : 'Unverified'}
        </Tag>
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        if (!record.verified) {
          return <div><Button onClick={() => onVerify(record)}>Verify</Button></div>
        } else {
          return <div><Button onClick={() => setEditContact(record)}>View</Button> </div>
        }
      },
    },
    {
      title: '',
      key: 'edit',
      render: (_, record) => {
        return <Button onClick={() => setEditContact(record)}>Edit</Button>
      },
    },
  ];
  const onSubmit = (values) => {
    api.post('/v1/profile/contacts', { ...editContact, ...values }).then(res => {
      fetchContacts();
    });
    setEditContact(null);
  }

  const onSubmitPro = (values) => {
    api.post('/v1/profile/pro', { ...editPro, ...values }).then(res => {
      fetchPro();
    });
    setEditPro(null);
  }

  const [form] = Form.useForm();
  const [formPro] = Form.useForm();

  const proList = ['BMI', 'ASCAP', 'SESAC', 'SOCAN', 'NA', 'APRA', 'STIM', 'BUMA', 'AACIMH', 'AAS', 'ABRAC', 'ABRAMUS', 'ACAM', 'ACCESS', 'ACDAM', 'ACUM', 'ADAGP', 'ADAVIS', 'ADDAF', 'AEI', 'AEPI', 'AGADU', 'AGAYC', 'AKKA-LAA', 'AKM', 'ALBAUTOR', 'ALCS', 'AMAR', 'AMCOS', 'AMPAL', 'AMRA', 'AMUS', 'ANACIM', 'APA', 'APDAYC', 'APG-JAP', 'APSAV', 'ARGENTOR', 'ARMAUTHO', 'ARS', 'ARTISJUS', 'ASDAC', 'ASDACS', 'ASSIM', 'ATIDA', 'ATN', 'AUME', 'AUTH REG', 'AUTODIO', 'AUTORART', 'AUTVIS', 'AWA', 'AWGACS', 'RAO'];


  return <Container title={t('TITLE')} isLoading={isLoading}>

    {contacts && !contacts.length ? <div>{t('UNVERIF')}<br /><br /><Button onClick={() => setEditContact({ id: null })}>{t('UNVERIF_BTN')}</Button></div> : <>
      <br />
      <h3>Contact Information</h3>
      <br />

      <Table columns={columns} dataSource={contacts} pagination={{
        position: ['none', 'none'],
      }} />
      <br />
      <br />
      <h3>Performance Rights Organizaion</h3>
      <br />
      {(pro && pro.length) ? <Table columns={proColumns} dataSource={pro} pagination={{
        position: ['none', 'none'],
      }} /> : <div>No information about your assosiation with PRO. <Button onClick={() => setEditPro({ id: null })}>Add info</Button></div>}
    </>
    }

    <Drawer placement="right" onClose={() => setEditPro(null)} open={editPro} width={500} title="Performance Rights Organizaion">
      <div className={styles.form}>
        <Form
          layout='horizontal'
          form={formPro}
          onFinish={onSubmitPro}
        >
          <Form.Item label="Name" name="pro" rules={[{ required: true, message: '' }]}>
            <Select size="large">
              {proList.map(p => (
                <Select.Option value={p} key={`pro_${p}`}>{p}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Identificator" name="identificator" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item>
            <Button submit block>Submit</Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer >

    <Drawer placement="right" onClose={() => setEditContact(null)} open={editContact} width={500} title="Contact">

      <div className="mb-8">
        <Alert message={t('FORM_MSG')} />
      </div>

      <div className={styles.form}>
        <Form
          layout='horizontal'
          form={form}
          onFinish={onSubmit}
          disabled={editContact?.verified}
        >
          <Form.Item label="First Name" name="firstName" rules={[{
            required: true,
            message: 'Only latin letters are allowed',
            pattern: new RegExp(/^[a-zA-Z]*$/),
          }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName" rules={[{
            required: true,
            message: '',
            message: 'Only latin letters are allowed',
            pattern: new RegExp(/^[a-zA-Z]*$/)
          }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Country" name="country" rules={[{ required: true, message: '' }]}>
            <Select size="large"
              showSearch>
              {countries.map(country => (
                <Select.Option value={country.code} key={`country_${country.code}`}>{country.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Address" name="address1" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Address 2" name="address2" rules={[{ required: false, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="City" name="city" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="State" name="state" rules={[{ required: false, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Postal Code" name="zip" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          <Form.Item label="Phone" name="phone" rules={[{ required: true, message: '' }]}>
            <Input placeholder="" size="large" />
          </Form.Item>
          {!editContact?.verified && <Form.Item>
            <Button block submit className="bg-blue-600 text-white">Submit</Button>
          </Form.Item>}
        </Form>
      </div>
    </Drawer >


  </Container >
}

export default App;
