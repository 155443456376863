import { Tabs, Table, Tag, Pagination, Drawer, Button, Modal, Form, Input } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../components/Container';
import api from '../../controllers/ApiController';
import { useTranslation } from 'react-i18next';


const Persona = require('persona');

function App({ admin }) {

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation('payments');

  useEffect(() => {
    setLoading(true);
    api.get('/v1/payments/').then(res => {
      setData(res.data)
      setLoading(false);
    }).catch(e => {

    });

  }, []);

  const columns = [
    {
      title: 'Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Details',
      dataIndex: 'paymentDetails',
      key: 'paymentDetails',
    },
    {
      title: 'Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      render: (text, record) => <div>${text}</div>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_) => (
        <Tag color="orange" >
          Pending
        </Tag >
      ),
    },
  ];



  const prepare = () => {
    return data.map(v => {
      return {
        key: v.id,
        ...v,
      }
    })
  }

  return <Container title={t('TITLE')} isLoading={isLoading}>
    {data.length ? <Table columns={columns} dataSource={prepare(data)} pagination={{
      position: ['none', 'none'],
    }} /> : <p>Payments will be available to you after your sign your first contract.</p>}
  </Container>
}

export default App;
