const PlayerController = {
  initialState: {
    volume: [80, 80],
    isPlay: false,
    current: null,
    isVisible: false,
    isLoading: true,
    position: 0,
    autoPlay: 0,
    repeat: false,
    startFrom: false,
    playlistId: null,
    track: {
      id: null,
      user: {
        name: null,
      },
    },
    tracks: [],
  },

  PLAYER_SET_TRACK: (state, params) => {

    const { track, position } = params;

    return {
      ...state,
      track,
      position,
      isLoading: true,
      isPlay: true,
      isVisible: true,
      progress: 0,
    }
  },


  PLAYER_SET_LOADING: (state, params) => ({ ...state, isLoading: true }),

  PLAYER_SET_LOADED: (state, params) => ({ ...state, isLoading: false }),

  PLAYER_SET_PLAYLIST: (state, { tracks, track, playlistId, position }) => {

    return {
      ...state,
      tracks,
      track,
      playlistId,
      position,
      isPlay: true,
      isVisible: true,
      isLoading: true,
    }
  },

  PLAYER_PLAY_NEXT_TRACK: (state, params) => {
    const { track, tracks } = state;
    const i = tracks.map(t => t.id).indexOf(track.id);
    let index = i + 1;
    if (index >= (tracks.length - 1)) index = 0; // first item

    const nextTrack = tracks[index] || track;

    return {
      ...state,
      track: nextTrack,
      progress: 0,
      position: 0,
      isPlay: true,
    }
  },

  PLAYER_PLAY_PREV_TRACK: (state, params) => {
    const { track, tracks } = state;
    const i = tracks.map(t => t.id).indexOf(track.id);
    let index = i - 1;
    if (index < 0) index = tracks.length - 1; // last item
    const nextTrack = tracks[index] || track;
    // document.keen._play(nextTrack);
    return {
      ...state,
      track: nextTrack,
      progress: 0,
      position: 0,
      isPlay: true,
    }
  },

  PLAYER_PLAY: (state, params) => {
    return { ...state, isPlay: true }
  },

  PLAYER_SET_REPEAT: (state, params) => {
    return { ...state, repeat: params }
  },

  PLAYER_SET_SETTINGS: (state, params) => {
    return { ...state, ...params }
  },

  PLAYER_SET_START_FROM: (state, params) => {
    return { ...state, startFrom: params }
  },

  PLAYER_SET_CURRENT: (state, params) => {
    return { ...state, current: params }
  },

  PLAYER_PAUSE: (state, params) => ({ ...state, isPlay: false }),

};

export default PlayerController;
