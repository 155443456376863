

import { Layout, Menu, Typography } from 'antd';
import React from 'react';
import { LockOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import NotFound from './pages/NotFound';
import api from './controllers/ApiController';
import { useDispatch, useSelector } from 'react-redux';
import styles from './auth.module.scss';
import { Checkbox, Form, Input } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from './components/Button';

const { Title } = Typography;





const Auth = ({ children, admin }) => {

  const dispatch = useDispatch();
  const { isAuthorized, role } = useSelector(state => state.user);
  const [type, setType] = useState(0);
  const [error, setError] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchUser = () => {
    api.get('/v1/user').then(res => {
      dispatch({
        type: "USER_SET", payload: {
          ...res.data,
          isAuthorized: true
        }
      })
    }).catch(e => {
      dispatch({
        type: "USER_SET", payload: {
          isAuthorized: false
        }
      })
    })
  }

  const onLogin = (values) => {
    dispatch({ type: "USER_RESET" });
    api.post('/v1/user/login', values).then(res => {
      dispatch({
        type: "USER_SET", payload: {
          ...res.data,
          isAuthorized: true
        }
      })
    }).catch(e => {
      dispatch({
        type: "USER_SET", payload: {
          isAuthorized: false
        }
      })
    })
  }

  const onSignUp = (values) => {
    dispatch({ type: "USER_RESET" });
    api.post('/v1/user/signup', values).then(res => {
      fetchUser();
    }).catch(e => {
      dispatch({
        type: "USER_SET", payload: {
          isAuthorized: false
        }
      })
    })
  }

  const onResetPassword = (values) => {
    api.post('/v1/user/forgotPassword', values).then(res => {
      setType(4);
    }).catch(e => {

    })
  }

  const setPassword = (values) => {
    api.post('/v1/user/setPassword', values, {
      headers: {
        'authorization': `Bearer ${searchParams.get('reset')}`
      }
    }).then(res => {
      setType(0);
    }).catch(e => {
      setError(e?.response?.data?.message ?? null)
    })
  }

  useEffect(() => {
    fetchUser();

    if (searchParams.get('reset')) {
      setType(3);
    }

  }, []);




  if (isAuthorized === null) {
    return <div className={styles.container}>
      <LoadingOutlined
        style={{
          fontSize: 32,
          color: '#003bff',
        }}
        spin
      />
    </div>
  }


  if (admin && !['admin', 'moderator'].includes(role)) {
    return <NotFound />
  }

  if (isAuthorized === false) {

    if (type === 0) {

      return <div className={styles.container}>

        <div className={styles.title}>
          <Title>Log in</Title>
        </div>

        <Form
          name="normal_login"
          className={styles.form}
          onFinish={onLogin}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: '' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>


          <Form.Item>
            <Button submit className="login-form-button" block size="large">
              Log in
            </Button>
          </Form.Item>
        </Form>
        <div className={styles.links}>
          <div>No account? <a onClick={() => setType(1)}>Create your account</a></div>
          <div><a onClick={() => setType(2)}>Forgot password?</a></div>
        </div>
      </div>;

    }

    if (type === 1) {

      return <div className={styles.container}>

        <div className={styles.title}>
          <Title>Sign Up</Title>
        </div>

        <Form
          name="normal_login"
          className={styles.form}
          onFinish={onSignUp}
        >
          <Form.Item
            name="name"
            rules={[{
              required: true,
              message: 'Only latin letters are allowed',
              pattern: new RegExp(/^[a-zA-Z ]*$/)
            }]}
          >
            <Input placeholder="Artist name" size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '' }]}
          >
            <Input
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            rules={[{ required: true, message: '' }]}
          >
            <Input
              type="password"
              placeholder="Confirm password"
              size="large"
            />
          </Form.Item>


          <Form.Item
            name="code"
            rules={[{ required: true, message: '' }]}
          >
            <Input
              type="text"
              placeholder="Invite code"
              size="large"
            />
          </Form.Item>


          <Form.Item>
            <Button submit className="login-form-button" block size="large">
              Create account
            </Button>
          </Form.Item>
        </Form>
        <div className={styles.links}>
          <div>Already have an account? <a onClick={() => setType(0)}>Log in </a></div>
        </div>
      </div>;

    }

    if (type === 2) {

      return <div className={styles.container}>

        <div className={styles.title}>
          <Title>Forgot Password?</Title>
        </div>

        <Form
          name="normal_login"
          className={styles.form}
          onFinish={onResetPassword}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block size="large">
              Reset password
            </Button>
          </Form.Item>
        </Form>
        <div className={styles.links}>
          <div>Already have an account? <a onClick={() => setType(0)}>Log in </a></div>
        </div>
      </div>;

    }

    if (type === 3) {
      return <div className={styles.container}>

        <div className={styles.title}>
          <Title>New password</Title>
        </div>

        {error && <div style={{ marginBottom: 32, color: 'red' }}>{error}</div>}

        <Form
          name="normal_login"
          className={styles.form}
          onFinish={setPassword}
        >
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: '' }]}
          >
            <Input
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: '' }]}
          >
            <Input
              type="password"
              placeholder="Confirm password"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" submit className="login-form-button" block size="large">
              Set password
            </Button>
          </Form.Item>
        </Form>
      </div>
    }

  }

  if (type === 4) {
    return <div className={styles.container}>
      <div className={styles.title}>
        <Title>Check your email</Title>
      </div>
    </div>
  }

  if (isAuthorized) {
    return children;
  }

  return null;

}



export default Auth;