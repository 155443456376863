import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './volume.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect } from 'react';

const cx = classNames.bind(styles);


const SvgSpeaker = ({ value }) => {

  switch (true) {
    case value > 70:
      return <svg
        width={24}
        height={24}
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4L6 8H3v8h3l4 4h2V4h-2zm9 8c0 2.21-.895 4.21-2.343 5.657l1.414 1.414A9.968 9.968 0 0021 12.011v-.023a9.968 9.968 0 00-2.929-7.06l-1.414 1.415A7.975 7.975 0 0119 12zm-2 0a5.981 5.981 0 00-1.757-4.243l-1.415 1.415A3.987 3.987 0 0115 12a3.987 3.987 0 01-1.172 2.828l1.415 1.415A5.98 5.98 0 0017 12z"
        />
      </svg>
    case value > 0:
      return <svg
        width={24}
        height={24}
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4L6 8H3v8h3l4 4h2V4h-2zm3.828 5.172l1.415-1.415A5.981 5.981 0 0117 12a5.98 5.98 0 01-1.757 4.243l-1.415-1.415A3.987 3.987 0 0015 12a3.987 3.987 0 00-1.172-2.828z"
        />
      </svg>
    default:
      return <svg
        width={24}
        height={24}
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.586l7.778-7.778 1.414 1.414-2.017 2.017A9.954 9.954 0 0121 11.99v.023a9.968 9.968 0 01-2.929 7.06l-1.414-1.415A7.975 7.975 0 0019 12a7.962 7.962 0 00-1.266-4.32l-1.459 1.459c.463.85.725 1.825.725 2.861a5.981 5.981 0 01-1.757 4.243l-1.415-1.415A3.987 3.987 0 0015 12c0-.474-.082-.93-.234-1.352L12 13.414V20h-2l-2.293-2.293-3.485 3.485-1.414-1.414 3.485-3.485L12 10.586zm0-2.829V4h-2L6 8H3v8h.757L12 7.757z"

        />
      </svg>
  }

}

const Volume = ({ onChange, defaultVolume = 0.8 }) => {

  const [volume, setVolume] = useState([defaultVolume * 100, defaultVolume * 100]);
  const mute = () => onChange(volume[0] > 0 ? 0 : volume[1]);
  const [isVisible, setVisible] = useState(false);
  const timeout = useRef();
  useEffect(() => {
    if (isVisible) {
      timeout.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [isVisible]);


  const onChangeVolume = (e) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
    setVolume([e, e]);
    onChange(e / 100);
  }


  return <div className={styles.volume}>
    <button onClick={() => setVisible(!isVisible)} title="Volume">
      <SvgSpeaker value={volume[0]} />
    </button>
    <div className={cx('overlay', { isVisible })}>
      <div>
        <Slider
          max={100}
          min={0}
          value={volume[0]}
          vertical
          onChange={onChangeVolume}
          trackStyle={{ background: 'white' }}
          railStyle={{ background: '#8C8B87' }}
          handleStyle={{ background: 'white', border: 'none', boxShadow: 'none', opacity: 1 }}
        />
      </div>
    </div>
  </div>
}

export default Volume;