
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    menu: {
      "BRIEFS": "Briefs",
      "TRACKS": "Tracks",
      "CONTRACTS": "Contracts",
      "PAYMENTS": "Payments",
      "PROFILE": "Profile",
      "FAQ": "FAQ",
      "LOGOUT": "Logout",
    },

    profile: {
      "TITLE": "Profile",
      "UNVERIF": "You must complete your personal info and verify your account to sell tracks.",
      "UNVERIF_BTN": "Add Info",
      "FORM_MSG": "Only latin characters allowed"
    },
    contracts: {
      "TITLE": "Contracts"
    },
    payments: {
      "TITLE": "Payments",
    },

    briefs: {
      "TITLE": "Briefs",
      "TEXT_VERIFY": "Verify your identity before access briefs.",
      "BTN_VERIFY": "Go to Profile"
    },

    tracks: {
      "TITLE": "Tracks",
      "NAME": "Name",
      "STATUS": "Status",
      "EDIT": "Edit",
      "TITLE_REQUIRED": "Title Required",
      "DETAILS": "Details",
      "PROGRESS": "Progress",
      "STEMS": "Stems",
      "VERSIONS": "Versions",
      "OFFERS": "Offers",
      "HISTORY": "History",
      "PROGRESS_UPLOAD_WAV": "Upload WAV",
      "PROGRESS_UPLOAD_WAV_DESCRIPTION": "Upload WAV",
      "PROGRESS_TRACK_INFO": "Information about the track",
      "PROGRESS_TRACK_INFO_DESCRIPTION": "Information about the track.",
      "PROGRESS_REVIEW": "Review",
      "PROGRESS_REVIEW_DESCRIPTION_1": "Your track needs to be updated, open the WAVs tab.",
      "PROGRESS_REVIEW_DESCRIPTION_2": "We are checking your track and will contact you soon.",
      "PROGRESS_APPROVAL": "Approval",
      "PROGRESS_APPROVAL_DESCRIPTION": "Approval",
      "PROGRESS_UPLOAD_VERSIONS": "Upload Versions and Stems",
      "PROGRESS_UPLOAD_VERSIONS_DESCRIPTION": "After the track is approved you will have to upload the archives",
      "PROGRESS_REVIEW_VERSIONS": "Review Versions and stems",
      "PROGRESS_REVIEW_VERSIONS_DESCRIPTION": "After the review is completed, you can accept the offer",
      "PROGRESS_CONTRACT": "Contract signing",
      "PROGRESS_CONTRACT_DESCRIPTION": "You need to go to the section with contracts and sign it",
      "UPDATE_WAV": "Click here to update track file",
      "TAG_DRAFT": "Draft",
      "TAG_OFFER": "Offer",
      "TAG_APPROVED": "Approved",
      "TAG_VERSIONS": "Upload Versions",
      "TAG_STEMS": "Upload Stems",
      "TAG_REJECTED": "Rejected",
      "TAG_ACTION": "Action Required",
      "TAG_FINISHED": "Finished",
      "TAG_REVIEW": "Review",
    }
  },
  ru: {
    menu: {
      "BRIEFS": "Брифы",
      "TRACKS": "Треки",
      "CONTRACTS": "Контракты",
      "PAYMENTS": "Платежи",
      "PROFILE": "Профиль",
      "FAQ": "FAQ",
      "LOGOUT": "Выход",
    },

    briefs: {
      "TITLE": "Брифы",
      "TEXT_VERIFY": "Добавьте информацию о себе и пройдите верификацию для доступа к брифам.",
      "BTN_VERIFY": "Перейти в профиль"
    },

    contracts: {
      "TITLE": "Контракты"
    },
    payments: {
      "TITLE": "Платежи"
    },

    profile: {
      "TITLE": "Профиль",
      "UNVERIF": "Вам необходимо добавить инфоморацию о себе и верифицировать профиль для загрузки и продажи треков",
      "UNVERIF_BTN": "Добавить информацию",
      "FORM_MSG": "Информация должна быть заполнена латинскими буквами"
    },

    tracks: {
      "TITLE": "Треки",
      "NAME": "Название",
      "STATUS": "Статусы",
      "EDIT": "Редактировать",
      "TITLE_REQUIRED": "Необходимо изменить название",
      "DETAILS": "Детали",
      "PROGRESS": "Прогресс",
      "STEMS": "Стемы",
      "VERSIONS": "Версии",
      "OFFERS": "Офферы",
      "HISTORY": "История",
      "PROGRESS_UPLOAD_WAV": "Загрузка WAV",
      "PROGRESS_UPDATE_DESCRUPTION": "Загрузка WAV",
      "PROGRESS_UPLOAD_WAV_DESCRIPTION": "Загрузите основную версию вашего трека в формате WAV",
      "PROGRESS_TRACK_INFO": "Информация о треке",
      "PROGRESS_TRACK_INFO_DESCRIPTION": "Внесите и сохраните все информацию о треки на вкладке Детали.",
      "PROGRESS_REVIEW": "Проверка",
      "PROGRESS_REVIEW_DESCRIPTION_1": "Трек требует изменений, откройте влкадку WAVs.",
      "PROGRESS_REVIEW_DESCRIPTION_2": "Проверяем загруженный трек.",
      "PROGRESS_APPROVAL": "Трек одобрен",
      "PROGRESS_APPROVAL_DESCRIPTION": "Поздравляем! Вы можете перейти к следующим шагам.",
      "PROGRESS_UPLOAD_VERSIONS": "Версии и стемы",
      "PROGRESS_UPLOAD_VERSIONS_DESCRIPTION": "Загрузите версии и стемы для трека согласно инструкции.",
      "PROGRESS_REVIEW_VERSIONS": "Проверка версий и стемов",
      "PROGRESS_REVIEW_VERSIONS_DESCRIPTION": "Мы проверяем загруженные архивы, следите за обновлениями.",
      "PROGRESS_CONTRACT": "Завершение сделки",
      "PROGRESS_CONTRACT_DESCRIPTION": "Теперь вы можете принять оффер. Перейдите на вкладку Офферы.",
      "UPDATE_WAV": "Нажмите здесь для загрузки нового WAV",
      "TAG_DRAFT": "Черновик",
      "TAG_OFFER": "Оффер",
      "TAG_APPROVED": "Трек одобрен",
      "TAG_VERSIONS": "Требуются версии",
      "TAG_STEMS": "Требуются стемы",
      "TAG_REJECTED": "Отклонен",
      "TAG_ACTION": "Требуются изменения",
      "TAG_FINISHED": "Завершен",
      "TAG_REVIEW": "Модерация",

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;