import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';
import classNames from 'classnames/bind';
import styles from './player.module.scss';
import Volume from './Volume';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const cx = classNames.bind(styles);

const toMinutes = (e) => {
  const m = Math.floor(e % 3600 / 60).toString().padStart(1, '0'),
    s = Math.floor(e % 60).toString().padStart(2, '0');
  return m + ':' + s;
}

export const Play = ({ isPlay, onClick, small }) => {

  const SvgPlay = () => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1968 11.4438C18.6087 11.6999 18.6087 12.2994 18.1968 12.5555L9.45461 17.9911C9.0186 18.2622 8.45446 17.9486 8.45446 17.4352L8.45446 6.56408C8.45446 6.05066 9.0186 5.73713 9.45461 6.00822L18.1968 11.4438Z" />
    </svg>
  );

  const SvgPause = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
      <rect x="7.5" y="6" width="3" height="12" rx="0.5" />
      <rect x="13.5" y="6" width="3" height="12" rx="0.5" />
    </svg>
  );

  return <div className={cx('play', { small })} onClick={onClick}>{isPlay ? <SvgPause /> : <SvgPlay />}</div>

}

const Player = () => {

  const currentTime = useRef();
  const wavesurfer = useRef();
  const waveform = useRef();
  const { track, isVisible, isPlay, position, isLoading } = useSelector(state => state.player);
  const [isMinimized, setMinimized] = useState(false);
  const dispatch = useDispatch();

  const log = useRef({ id: null, start: null, end: null });

  const volume = 0.8;

  function init() {
    if (!wavesurfer.current) {

      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent || '') ||
        /iPad|iPhone|iPod/i.test(navigator.userAgent || '');

      const waveOptions = {
        container: waveform.current,
        backend: isSafari ? 'MediaElement' : 'WebAudio',
        mediaType: 'audio',
        height: 30,
        normalize: true,
        waveColor: '#f5f5f5',
        progressColor: '#1677ff',
        barWidth: 2,
        forceDecode: false,
        cursorWidth: 0,
        volume,
        plugins: [],
      }

      if (window.WaveSurfer.cursor) {
        waveOptions.plugins.push(window.WaveSurfer.cursor.create({
          showTime: true,
          formatTimeCallback: (timeValue) => toMinutes(timeValue),
          opacity: 1,
          color: 'white',
          customShowTimeStyle: {
            'background-color': '#1677ff',
            'color': 'white',
            'font-size': '12px',
            'padding': '4px',
          }
        }))
      }

      wavesurfer.current = window.WaveSurfer.create(waveOptions);
    }
  }


  const events = () => {

    wavesurfer.current.unAll();

    wavesurfer.current.on('audioprocess', throttle((seconds) => {
      if (seconds > 0) {
        const duration = track.duration;
        const progress = 1 / (duration / seconds);
        if (currentTime.current) {
          currentTime.current.innerText = toMinutes(seconds);
        }
      }
    }, 250));

    wavesurfer.current.on('seek', throttle((progress) => {
    }, 100));

    wavesurfer.current.on('ready', e => {
      wavesurfer.current.play(position ?? 0);
      dispatch({ type: 'PLAYER_SET_LOADED' });
    });


    wavesurfer.current.on('loading', throttle((e) => {
      if (e < 100) {
        dispatch({ type: 'PLAYER_SET_LOADING' });
      }
    }, 1000, { leading: false }));


    wavesurfer.current.on('error', e => {
      //this.debug('Track backend error:', e);
    });
  }

  useEffect(() => {
    if (wavesurfer.current && track.preview) {
      events();
      dispatch({ type: 'PLAYER_SET_LOADED' });
      wavesurfer.current.stop();
      wavesurfer.current.load(track.preview);
      if (track.waveform) {
        fetch(track.waveform).then((response) => response.json()).then(res => {
          //wavesurfer.current.backend.peaks = Helpers.normalizeWave(res.data);
          wavesurfer.current.drawBuffer();
        })
      }
    }
  }, [track]);

  useEffect(() => {
    if (wavesurfer.current && track.id && !isLoading) {
      if (isPlay) {
        wavesurfer.current.play();
      } else {
        wavesurfer.current.pause();
      }
    }
  }, [isPlay]);

  useEffect(() => {
    init();
  }, []);


  const _play = () => dispatch({ type: 'PLAYER_PLAY' });
  const _pause = () => dispatch({ type: 'PLAYER_PAUSE' });
  const _prev = () => dispatch({ type: 'PLAYER_PLAY_PREV_TRACK' });
  const _next = () => dispatch({ type: 'PLAYER_PLAY_NEXT_TRACK' });

  const onVolumeChange = (value) => {
    if (wavesurfer.current) {
      wavesurfer.current.setVolume(value);
    }
  }

  return <>
    <div className={cx('container', { showPlayer: isVisible, isMinimized })}>
      <div className={styles.player}>

        <div className={styles.info}>
          <Link href={`/track/${track.id}-${track.alias}/`}>{track.title}</Link>
          {track?.Artist && <div><span>by</span> <Link href={`/artist/${track?.Artist?.slug}/`}>{track?.Artist?.name}</Link></div>}
        </div >


        <Play onClick={() => isPlay ? _pause() : _play()} isPlay={isPlay} />

        <div className={styles.duration} ref={currentTime}>0:00</div>
        <div ref={waveform} className={styles.wave}></div>
        <div className={styles.duration}>{toMinutes(track.duration)}</div>
        {/* <Volume onChange={onVolumeChange} defaultVolume={volume} /> */}


      </div >
    </div >
  </>
}

export default Player;