import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import Auth from './Auth';
//import { Layout, Menu } from 'antd';
import { Fragment, useState } from 'react'

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  ArrowLeftCircleIcon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  FlagIcon,
  DocumentDuplicateIcon,
  MusicalNoteIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  NewspaperIcon,
  UserIcon,
  QuestionMarkCircleIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon, User, } from '@heroicons/react/20/solid'




import api from './controllers/ApiController';
import { useSelector } from 'react-redux';
import Player from './components/Player';
import { useEffect } from 'react';

//const { Header, Content, Footer, Sider } = Layout;


const App = ({ admin }) => {

  const { role } = useSelector(state => state.user);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { t, i18n } = useTranslation('menu');
  const [lang, setLang] = useState('en');

  useEffect(() => {
    const ls = window.localStorage.getItem('lang');
    if (ls && ['ru', 'en'].includes(ls)) {
      handleChangeLang(ls);
    }
  }, [])

  const handleChangeLang = (lang) => {

    console.log(lang);

    setLang(lang);
    window.localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  }

  const navigation = [
    { name: 'BRIEFS', href: 'briefs', icon: NewspaperIcon },
    { name: 'TRACKS', href: 'tracks', icon: MusicalNoteIcon },
  ]

  if (role !== 'moderator') {
    navigation.push({ name: 'CONTRACTS', href: 'contracts', icon: DocumentDuplicateIcon })
    navigation.push({ name: 'PAYMENTS', href: 'payments', icon: CurrencyDollarIcon })
  }

  if (role === 'admin') {
    navigation.push({ name: 'Versions', href: 'versions', icon: HomeIcon })
    navigation.push({ name: 'Stems', href: 'stems', icon: HomeIcon })
  }

  if (role === 'seller') {
    navigation.push({ name: 'PROFILE', href: 'profile', icon: UserIcon })
    navigation.push({ name: 'FAQ', href: 'faq', icon: QuestionMarkCircleIcon, })
  }

  navigation.push({ name: 'LOGOUT', href: 'logout', icon: ArrowLeftCircleIcon })



  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const items = [
    { key: 'briefs', label: "Briefs" },
    { key: 'tracks', label: "Tracks" },
    { key: 'contracts', label: "Contracts" },
    { key: 'payments', label: "Payments" },
  ]

  const onClick = (e) => {
    navigate(`/${admin ? 'admin/' : ''}${e}/`);
  }

  const langs = {
    ru: "Русский",
    en: "English",
  }

  return <Auth admin={admin}>
    <div>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <div className="flex h-20 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="/logo.svg"
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li className="flex-grow">
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => {
                    const isCurrent = location.pathname.includes(`/${item.href}/`);
                    return <li key={item.name} >
                      <a
                        onClick={() => onClick(item.href)}
                        className={classNames(
                          isCurrent
                            ? 'bg-gray-50 text-blue-700'
                            : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            isCurrent ? 'text-blue-700' : 'text-gray-400 group-hover:text-blue-600',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {t(item.name)}
                      </a>
                    </li>
                  })}
                </ul>
              </li>

              <li>
                <Menu as="div" className="relative inline-block text-left w-full">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {langs[lang]}
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 bottom-12 w-full z-10 mt-2   origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {Object.entries(langs).map(([k, v]) => <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => handleChangeLang(k)}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm cursor-pointer'
                              )}
                            >
                              {v}
                            </a>
                          )}
                        </Menu.Item>)}
                      </div>


                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>

            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72 pb-16">
        <main className="py-0">
          <div className="px-4 sm:px-6 lg:px-4">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
    <Player />
  </Auth>

  // return <Auth admin={admin}>
  //   <Layout hasSider>
  //     <Sider
  //       width={250}
  //       style={{
  //         overflow: 'auto',
  //         height: '100vh',
  //         position: 'fixed',
  //         left: 0,
  //         top: 0,
  //         bottom: 0,
  //       }}
  //     >
  //       <div className="logo">
  //         <img src="/logo.svg" />
  //       </div>



  //       <Menu theme="light" mode="inline" selectedKeys={selectedKeys()} items={items} onClick={onClick} />
  //     </Sider>
  //     <Layout
  //       style={{
  //         marginLeft: 250,
  //         paddingBottom: 100,
  //       }}
  //     >
  //       <Content>
  //         <div className="site-layout-background">
  //           <Outlet />
  //         </div>
  //       </Content>
  //     </Layout>
  //   </Layout >
  //   <Player />
  // </Auth >
};

export default App;