import React from 'react';
import styles from './play.module.scss';
import classNames from 'classnames/bind';
import { SvgPlay, SvgPause } from '../../utils/Icons';
const cx = classNames.bind(styles);

const Play = ({ onPlay, isLoading, isCurrent, isPlay, large, light, size, transparent }) => {
  return <button
    title={isPlay ? "Pause" : "Play"}
    onClick={onPlay}
    className={cx('btn', {
      isPause: (isPlay && isCurrent),
      loading: isLoading,
      size_40: size === 40,
      large,
      light,
      transparent
    })}
  >{isLoading ? <div className={styles.dots} /> : isPlay ? <SvgPause /> : <SvgPlay />}</button>
}

export default Play;

