import { Tabs, Table, Tag, Progress, Modal, Form, Input, DatePicker, Space, Checkbox } from 'antd';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Container from '../../components/Container';
import api from '../../controllers/ApiController';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';

function App({ admin }) {

  const [uploadModal, setUploadModal] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isUploading, setUploading] = useState(false);
  const [edit, setEdit] = useState();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(0);
  const [modal, setModal] = useState();
  const [isVerified, setVerified] = useState(null);
  const navigate = useNavigate();
  const uploadRef = useRef();
  const { t } = useTranslation('briefs');

  const fetchBriefs = () => {
    setLoading(true);
    api.get('/v1/briefs').then(res => {
      setData(res.data)
      setLoading(false);
    }).catch(e => {

    });
    if (!admin) {
      setTimeout(() => {
        fetchBriefs();
      }, 900000)
    }
  }

  const checkVerified = () => {
    api.get('/v1/profile/contacts').then(res => {
      const verified = false;
      res.data.map(v => {
        if (v.verified) {
          verified = true;
        }
      })



      setVerified(verified);
    }).catch(e => {

    });
  }

  useEffect(() => {
    fetchBriefs();
    checkVerified();
  }, []);


  useEffect(() => {
    setEdit(modal);
  }, [modal])

  const handleUpload = async (e) => {

    setUploading(true);

    var formData = new FormData();
    var file = document.querySelector('#preview');
    formData.append("audio", file.files[0]);
    formData.append("briefId", uploadModal);
    api.post('/v1/tracks/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(percentCompleted);
      }
    }).then(res => {
      navigate('/tracks/');
    }).finally(() => {
      e.target.value = ''
      setUploading(false);
      setUploadModal(null);
    })
  }


  const columns = [
    {
      title: 'Genre',
      dataIndex: 'genre',
      key: 'genre',
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => <div>{text ? text.substring(0, 100).replace(/(&nbsp;|<([^>]+)>)/ig, '') : null}...</div>,
    },
    {
      title: 'Final Date',
      dataIndex: 'finalDate',
      key: 'finalDate',
      render: (text, record) => <div>{new Date(text).toDateString()}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <div>{text ? 'Active' : "Closed"}</div>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        return <Button onClick={() => setModal(record)}>View</Button>
      },
    },
  ];

  if (!admin) {
    columns.push({
      title: '',
      key: 'action',
      render: (_, record) => {
        return record.status === 1 ? <Button onClick={() => setUploadModal(record.id)}>Submit Track</Button> : <div className="text-center font-bold">Closed</div>;
      },
    })
  }

  const getTags = (v) => {
    const tags = [];

    if (v.status === 0) {
      tags.push('draft');
    }

    if (v.status === 1) {
      tags.push('sumbitted');
    }

    if (v.status === 2) {
      tags.push('sent');
    }

    if (v.status === 3) {
      tags.push('finished');
    }

    return tags;

  }

  const prepare = () => {
    return data.map(v => {
      return {
        key: v.id,
        ...v,
        tags: getTags(v)
      }
    })
  }

  const editorRef = useRef(null);

  const handleOk = () => {
    if (admin) {
      const obj = {
        ...modal,
        ...edit,
        description: editorRef.current.getContent()
      }
      api.post('/v1/admin/briefs', obj).then(res => {
        fetchBriefs();
      })
    }
    setModal(null);
  }

  const createBrief = () => {
    if (editorRef.current) {
      editorRef.current.setContent("")
    }
    setEdit({});
    setModal({ id: null });
  }

  const upload = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }

  if (!admin && isVerified === false) {
    return <Container title={t('TITLE')} >
      <div>{t('TEXT_VERIFY')}</div>
      <br />
      <Button type="primary" onClick={() => navigate('/profile/')}>{t('BTN_VERIFY')}</Button>
    </ Container>
  }


  return <Container title={t('TITLE')} isLoading={isLoading} extra={admin && <Button type="primary" onClick={createBrief}>Create Brief</Button>}>

    <Modal
      open={uploadModal}
      title="STEMS"
      onCancel={() => setUploadModal(null)}
      footer={isUploading ? <Progress percent={progress} /> : <Button type="primary" onClick={upload}>Upload</Button>}
    >
      <p>Мы принимаем треки только с версиями и стемами. Вам не нужно загружать их сейчас. Как только ваш трек будет одобрен вы сможете загрузить их.</p>
      <p>Больше информации вы можете прочитать здесь:</p>
      <p>
        <a href="https://docs.google.com/document/d/18ilX5eNSUz7YYvG3Dee66BO3Gj0ErPY-wSXgjy-_1yE/edit?usp=sharing" rel="noreferrer" target="_blank" className='underline'>Как делать STEMS / VERSIONS</a><br />
      </p>
    </Modal>

    <Table columns={columns} dataSource={prepare(data)} pagination={{
      position: ['none', 'none'], pageSize: 100
    }} />
    <br />
    <Modal title={modal?.genre} open={modal} onCancel={() => setModal(null)} onOk={handleOk} width={800}>

      {admin ? <Space direction="vertical" style={{ width: '100%' }}>
        <Input placeholder="Genre / Style" onChange={(e) => setEdit({ ...modal, ...edit, genre: e.target.value })} />
        <DatePicker style={{ width: '100%' }} onChange={(e) => setEdit({ ...edit, finalDate: e.$d })} />
        <Editor
          apiKey='14eq8e9fc0yyj1vb2m2gxjytp8w4nihum11y94utew2ewnah'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={modal?.description ? `<p>${modal?.description}</p>` : null}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'link'
            ],
            toolbar: 'link undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
        {/* <Checkbox onChange={(e) => setModal({ ...modal, status: e.target.checked ? 1 : 0 })} value={modal?.status} /> Active */}
      </Space> : <p dangerouslySetInnerHTML={{ __html: modal?.description }} />}


    </Modal>

    <input id="preview" name="file" type="file" ref={uploadRef} accept=".wav" onChange={handleUpload} style={{ display: 'none' }} />

  </Container >
}

export default App;
