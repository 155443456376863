import { Table, Tag, Pagination, Modal, Button } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import Container from '../../components/Container';
import TrackEditor from '../../components/TrackEditor';
import api from '../../controllers/ApiController';
import styles from './tracks.module.scss';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';


const cx = classnames.bind(styles);

function App({ admin }) {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [play, setPlay] = useState({});
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(1);
  const audioRef = useRef();
  const { t } = useTranslation('tracks');

  const fetchMusic = (p) => {

    const userId = searchParams.get('userId');
    const trackId = searchParams.get('trackId');

    api.get(admin ? `/v1/admin/tracks?page=${p}&userId=${userId}&trackId=${trackId}&status=${status}` : `/v1/tracks?page=${p}`).then(res => {
      setData(res.data);
      setLoading(false);
    })
  }

  useEffect(() => {
    fetchMusic(page);
  }, [page, status])

  const onUpdate = (id) => {
    api.get(admin ? '/v1/admin/tracks/' + id : '/v1/tracks/' + id).then(res => {
      setEdit(res.data);
      setData({ ...data, rows: data.rows.map(v => v.id === id ? res.data : v) });
    })

  }

  const onPlay = (track) => {
    if (play.id === track.id) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    } else {
      audioRef.current.src = track.preview;
      audioRef.current.play();
      setPlay(track);
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'preview',
      key: 'preview',
      render: (preview, track) => (<div className={cx('play', { isPlay: play.id !== track.id })} onClick={() => onPlay(track)} />)
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Brief',
      dataIndex: 'Brief',
      key: 'Brief',
      render: (value) => (value ? value.genre : '')
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {

            let color;

            switch (tag) {
              case "rejected":
                color = 'red';
                break;
              case "action required":
                color = 'orange';
                break;
              case "review":
                color = 'orange';
                break;
              case "approved":
                color = 'green';
                break;
              case "stems":
                color = 'red';
                break;
              case "versions":
                color = 'red';
                break;
              case "finished":
                color = 'green';
                break;
              case "offers":
                color = 'green';
                break;
              default:
                color = 'grey';
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {

        let text = "View";

        if (record.status < 0) {
          text = "Update";
        } else if (record.status === 0) {
          text = "Edit";
        }

        return <a onClick={() => onUpdate(record.id)}>{text}</a>
      },
    },
  ];

  const getTags = (v) => {
    const tags = [];

    if (v.status === -2) {
      tags.push({ color: 'red', text: 'rejected' });
    }

    if (v.status === -1) {
      tags.push({ color: 'orange', text: 'action' });
    }

    if (v.status === 0) {
      tags.push({ color: 'grey', text: 'draft' });
    }

    v.Offers.map(v => {
      if (v.status === 0) {
        if (!tags.includes('offer')) {
          tags.push({ color: 'green', text: 'offer' });
        }
      }
    })

    if (v.status === 1) {
      tags.push({ color: 'orange', text: 'review' });
    }

    if (v.status === 2) {
      tags.push({ color: 'green', text: 'approved' });
    }

    if (v.status === 2 && (v?.TrackStem?.status === 0)) {
      tags.push({ color: 'orange', text: 'stems' });
    }

    if (v.status === 2 && (v?.TrackVersion?.status === 0)) {
      tags.push({ color: 'orange', text: 'versions' });
    }

    if (v.status === 11) {
      tags.push({ color: 'green', text: 'finished' });
    }



    return tags.map(v => <Tag color={v.color} key={v.text}>
      {t(`TAG_${v.text.toUpperCase()}`)}
    </Tag>
    );

  }

  const prepare = ({ rows }) => {

    if (!rows) return [];

    return rows.map(v => {
      return {
        key: v.id,
        ...v,
        tags: getTags(v)
      }
    })
  }

  return <Container title={t('TITLE')} isLoading={isLoading}>
    <div className="">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">

                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('NAME')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('STATUS')}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.rows && data.rows.map((track) => (
                  <tr key={track.email}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className={cx('play', { isPlay: play.id !== track.id })} onClick={() => onPlay(track)} />
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 cursor-pointer" onClick={() => onUpdate(track.id)}>
                      <div className="text-gray-900 font-bold">{track.title ?? <span className="text-red-500">{t('TITLE_REQUIRED')}</span>}</div>
                      <div className="mt-1 text-gray-400">{track.fileName}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {getTags(track)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{track.role}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a onClick={() => onUpdate(track.id)} className="text-blue-600 hover:text-blue-300 cursor-pointer">
                        {t('EDIT')}<span className="sr-only"></span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TrackEditor data={edit} onClose={() => setEdit(null)} admin={admin} onUpdate={onUpdate} />
      <audio ref={audioRef} style={{ display: 'none' }} />
    </div>
  </Container>

  return <Container title="Tracks" isLoading={isLoading}>
    <Table columns={columns} dataSource={prepare(data)} pagination={{
      position: ['none', 'none'], pageSize: 50
    }} />
    <br />
    {data.count > 20 && <Pagination defaultCurrent={page} total={data.count} pageSize={20} onChange={setPage} />}
    <TrackEditor data={edit} onClose={() => setEdit(null)} admin={admin} onUpdate={onUpdate} />
    <audio ref={audioRef} style={{ display: 'none' }} />
  </Container >
}

export default App;
