import React, { useRef } from 'react';
import Container from '../../components/Container';

function Faq() {

  return <Container title="FAQ">
    <a className="underline" href="https://docs.google.com/document/d/17QdGoyflbHjDG2TefgZiaJtv49IL4-yUB1fv97_I0wo/edit" target="_blank">https://docs.google.com/document/d/17QdGoyflbHjDG2TefgZiaJtv49IL4-yUB1fv97_I0wo/edit</a>
  </Container >
}

export default Faq;
