import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;
const ApiController = axios.create({ baseURL });

ApiController.interceptors.request.use(async config => {
  //const AccessToken = window.localStorage.getItem('AccessToken');
  //if(AccessToken) config.headers['Authorization'] = `Bearer ${AccessToken}`;
  config.withCredentials = true;
  return config;
}, error => {
  return Promise.reject(error)
});

// выход при status 401
ApiController.interceptors.response.use(response => {
  return response
}, async (error) => {

  if (error.response && error.response.status == 401) {

    //  Auth.exit('401 доступ запрещен')
  } else if (error.response && error.response.status == 429) {
    await new Promise(resolve => setTimeout(resolve, 1000))
    return axios.request(error.config)
  } else if (error.response && error.response.status == 404) {
    //console.log('404!!!!');
    const err = new Error('Not found')
    err.code = 'ENOENT'
    throw err;
  }
  // if 429 send 2nd
  return Promise.reject(error)
});

ApiController.url = baseURL;

export default ApiController;
